import styled from "styled-components";
import media from "@styles/media";
import { H1, Description } from "@components/Typography";

const HomeHeaderMain = styled.div`
  height: 100%;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${media.toTabletP`
    width: auto;
    margin-top: -50px;
    background-color: white;
    border-radius: 20px;
    padding-top: 50px;
    padding-left: 20px;
    padding-right: 20px;
    ${H1} {
      text-align: center;
      display: flex;
      justify-content: center;
    }
  `}
  ${H1} {
  }
  ${Description} {
    ${media.toTablet`
      font-size: 16px;
      line-height: 27px;
    `}
  }
`;

export default HomeHeaderMain;
