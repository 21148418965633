import React from "react";
import { graphql, PageProps } from "gatsby";

import Layout from "../ui/layout";
import Home from "@views/Home";
import HomeHeader from "@views/Home/components/HomeHeader";
import categories, { Category } from "@data/categories";

const IndexPage: React.FC<PageProps> = props => {
  // @ts-ignore
  const data = props?.data?.allTemplate.edges.map(
    (item: any) => item.node.data,
  );

  const rows = categories.map((item: Category) => {
    const templates = data.filter(
      (template: any) => template.description.category === item.key,
    );
    return {
      key: item.key,
      title: item.title,
      templates,
    };
  });

  return (
    <Layout
      header={{ children: <HomeHeader /> }}
      seo={{
        title: "100+ Best React Templates and Themes [2024]",
        description:
          "Reactemplates. Search through a variety of react templates, react blogs, dashboards, landing pages, react portfolios, stores and many more. Check it out!",
      }}
    >
      <Home data={rows} />
    </Layout>
  );
};

export const query = graphql`
  query HomeQuery {
    allTemplate(sort: { fields: data___createdAt____seconds }) {
      edges {
        node {
          data {
            name
            description {
              shortDescription
              category
            }
            price {
              currentPrice
              initialPrice
            }
            slug
            thumbnailImages {
              thumbnail {
                childImageSharp {
                  fluid(maxWidth: 560, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
